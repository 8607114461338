<template>
	<div class="kousao">
		<el-row>
			<el-col :span="12" style="text-align: center;">
				<h4>上传口扫</h4>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">请在此处上传口扫文件。请注意文件格式。</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">您可以在<a
						href="#">此处</a>找到有关图片的示例。(点击后跳转出示例文件页)</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">重要提示：要创建一个治疗方案，需要上、下颌的3D扫描文件及咬合记录。
				</div>

				<div style="width: 70%;margin: 0px auto;">
					<el-form :model="ruleForm" ref="ruleForm">
						<el-form-item prop="chengxiangDate" label="成像日期" required>
							<el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.chengxiangDate"
								style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>
						</el-form-item>
						<el-form-item prop="jzType" label="矫治器类型" required>
							<el-select @change="selectJZType" v-model="ruleForm.jzType" placeholder="请选择">
								<el-option v-for="item in jzTypes" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>

				</div>
				<div align="left" style="padding-left: 6rem;">注意</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">图像需近30天内</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">有效格式：.stl、.obj</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">最小文件大小：2MB</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">最大文件大小：25MB</div>
			</el-col>
			<el-col :span="12"
				style="text-align: left;padding-left: 30px;padding-top: 0.625rem;overflow-y: auto;height: 430px;">
				<!-- 上颌 -->
				<div class="shanghe" v-if="sh">
					<div style="font-size: 0.875rem;">请上传上颌</div>
					<el-upload class="upload-demo" drag action='' ref="upload" :http-request="changeFile" multiple accept=".png,.stl,.obj">
						 <span class="sh"></span><br />
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					<!--  accept=".jpg,.png"
					:before-upload="beforeAvatarUpload" -->
				</div>
				<!-- 下颌 -->
				<div class="xiahe" v-if="xh">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传下颌</div>
					<el-upload class="upload-demo1" drag action='' multiple :http-request="changeFile1" accept=".png,.stl,.obj">
						 <span class="xh"></span><br />
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip"></div>
					</el-upload>
				</div>
				<!-- 咬合 -->
				<div class="yaohe" v-if="yh">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传咬合</div>
					<el-upload class="upload-demo1" drag action='' multiple :http-request="changeFile2" accept=".png,.stl,.obj">
						<span class="yh"></span><br />
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip"></div>
					</el-upload>
				</div>
				<button @click="goOrderThree" class="btnNextKouNei" style="width: 30%;" type="button">返回</button>
				<button @click="nextStepKN" class="btnNextKouNei" style="width: 30%;" type="button">下一步</button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				sh: true,
				xh: true,
				yh: true,
				ruleForm: {
					chengxiangDate: '',
					oid: '',
					jzType: '',
				},
				jzTypes: [{
					value: '不带颌垫的上颌矫治器',
					label: '不带颌垫的上颌矫治器'
				}, {
					value: '不带颌垫的下颌矫治器',
					label: '不带颌垫的下颌矫治器'
				}, {
					value: '带颌垫的矫治器',
					label: '带颌垫的矫治器'
				}]
			}
		},
		methods: {
			//选择矫治器类型
			selectJZType(value) {
				if (value == "不带颌垫的上颌矫治器") { //不带颌垫的上颌矫治器
					this.xh = false;
					this.yh = false;
					this.sh = true;
				} else if (value == "不带颌垫的下颌矫治器") { //不带颌垫的下颌矫治器
					this.xh = true;
					this.yh = false;
					this.sh = false;
				} else if (value == "带颌垫的矫治器") { //带颌垫的矫治器
					this.xh = true;
					this.yh = true;
					this.sh = true;
				}
			},
			// beforeAvatarUpload(file) {
			//     console.log('file', file)
			//     let types = ['image/jpeg', 'image/jpg', 'image/png'];
			//     const isImage = types.includes(file.type);
			//     if (!isImage) {
			//         this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			//       }else{
			//         //this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			//       }
			// 	  const isLtSize = file.size / 1024 / 1024 < 5;
			// 	   if (!isLtSize) {
			// 	            this.$message.error('上传图片大小不能超过 1MB!');
			// 	       }
			//     },

			//上传上颌
			changeFile(file) {
				console.log(file);
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksShang')
				console.log(formData.get('type'));
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						console.log(res);
						if (res.data.code == 200) {
							this.$message({
								message:"上传上颌成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//上传下颌
			changeFile1(file) {
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksXia')
				console.log(formData.get('type'));
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						if (res.data.code == 200) {
							this.$message({
								message:"上传下颌成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//上传下颌
			changeFile2(file) {
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksYao')
				console.log(formData.get('type'));
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						if (res.data.code == 200) {
							this.$message({
								message:"上传咬合成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//下一步
			nextStepKN() {
				if(this.ruleForm.chengxiangDate==''){
					this.$message({
						message:"请选择成像日期",
						type:"warning",
						duration:900
					});
					
				}else if(this.ruleForm.jzType==''){
					this.$message({
						message:"请选择矫治器类型",
						type:"warning",
						duration:900
					});
				}else{
					//上传日期
					let formData1 = new FormData()
					formData1.append('date', this.ruleForm.chengxiangDate) // 传文件
					formData1.append('oid', this.ruleForm.oid)
					formData1.append('type', "ksDate")
					
					this.$axios.post("client/order/uploadResourceDate", formData1, {
						headers: {
							"Content-type": "application/json"
						}
					}).then(res => {
						console.log(res);
						if (res.data.code != 200) {
							this.$message({
								message:"添加成像日期失败",
								type:"error",
								duration:900
							});
						}
					}).catch(err => {
						this.$message({
							message:"添加成像日期失败",
							type:"error",
							duration:900
						});
					});
					//上传矫治器类型
					let formData2 = new FormData()
					console.log("矫治器类型" + this.ruleForm.jzType);
					formData2.append('date', this.ruleForm.jzType) // 传文件
					formData2.append('oid', this.ruleForm.oid)
					formData2.append('type', "jzqlx")
					this.$axios.post("client/order/uploadResourceDate", formData2, {
						headers: {
							"Content-type": "application/json"
						}
					}).then(res => {
						console.log(res);
						if (res.data.code == 200) {
							this.$message({
								message:"添加矫治器类型成功",
								type:"success",
								duration:900
							});
							this.$router.push("/addorder/orderfive")
						}
					}).catch(err => {
						this.$message({
							message:"添加矫治器类型失败",
							type:"error",
							duration:900
						});
					});
					
				}
				
			},
			//返回
			goOrderThree(){
				this.$router.push("/addorder/orderthree")
			}
		},
		mounted: function() {
			this.$store.commit('setStepIndex', 3);
			//设置默认地址
			sessionStorage.setItem("location","orderfour")
			//获取当前添加的订单oid
			//获取oid
			let oid = sessionStorage.getItem("oid");
			console.log("口扫" + oid);
			//this.ruleForm.oid="5e63248b2006487ebdcb5ff381f1360b"; 
			if(oid==null){
				this.$alert("请填写患者信息","提示");
				this.$router.push("/addorder/orderone");
			}else{
				this.ruleForm.oid=oid;
			}
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res.data.data.resource);
					let ksDate=res.data.data.resource.ksDate;//口扫日期
					let jzqlx=res.data.data.resource.jzqlx;//矫治器类型
					let shUrl=res.data.data.resource.ksShang;//上颌
					let xhUrl=res.data.data.resource.ksXia;//下颌
					let yhUrl=res.data.data.resource.ksYao;//咬合
					//日期回显
					if(ksDate!=null){
						this.ruleForm.chengxiangDate=ksDate;
					}
					//矫治器类型回显
					if(jzqlx!=null){
						this.ruleForm.jzType=jzqlx;
						if (jzqlx == "不带颌垫的上颌矫治器") { //不带颌垫的上颌矫治器
							this.xh = false;
							this.yh = false;
							this.sh = true;
						} else if (jzqlx == "不带颌垫的下颌矫治器") { //不带颌垫的下颌矫治器
							this.xh = true;
							this.yh = false;
							this.sh = false;
						} else if (jzqlx == "带颌垫的矫治器") { //带颌垫的矫治器
							this.xh = true;
							this.yh = true;
							this.sh = true;
						}
					}
					//回显上颌
					if(shUrl!=null&&shUrl!=''){
						document.getElementsByClassName("sh")[0].innerHTML="上颌图片已上传"
						document.getElementsByClassName("sh")[0].style.color="#2B9BB3";
						document.getElementsByClassName("sh")[0].style.fontWeight="bolder";
					}
					//回显下颌
					if(xhUrl!=null&&xhUrl!=''){
						document.getElementsByClassName("xh")[0].innerHTML="下颌图片已上传"
						document.getElementsByClassName("xh")[0].style.color="#2B9BB3";
						document.getElementsByClassName("xh")[0].style.fontWeight="bolder";
					}
					//回显咬合
					if(yhUrl!=null&&yhUrl!=''){
						document.getElementsByClassName("yh")[0].innerHTML="侧位图片已上传"
						document.getElementsByClassName("yh")[0].style.color="#2B9BB3";
						document.getElementsByClassName("yh")[0].style.fontWeight="bolder";
					}
				}).catch(err=>{
					console.log(err);
				});
			}
			
		}
	}
</script>

<style scoped="scoped">
	.kousao {
		background-image: linear-gradient(to bottom, white, #9BEDEB, #28D9D5);
		height: 100%;
	}

	.btnNextKouNei {
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
		margin-bottom: 1.25rem;
	}
	.xy {
		display: none;
	}
	
</style>
